/* tslint:disable */
/* eslint-disable */
/**
 * Tractable Identity Broker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TokenResponse
 */
export interface TokenResponse {
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    accessToken: string;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    idToken: string;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    refreshToken?: string;
    /**
     * The (customer) client ID that the user is using. This is the canonical client ID.
     * @type {string}
     * @memberof TokenResponse
     */
    clientId: string;
    /**
     * Comma-separated list of all aliases for the client ID that is being used, including the canonical client ID
     * @type {string}
     * @memberof TokenResponse
     */
    clientIdAliases?: string;
    /**
     * The (customer) client ID that the user is impersonating. Only applicable for internal users.
     * @type {string}
     * @memberof TokenResponse
     */
    impersonatedClientId?: string;
    /**
     * Seconds to expiration of the access token
     * @type {number}
     * @memberof TokenResponse
     */
    expiresIn?: number;
    /**
     * Type of the access token
     * @type {string}
     * @memberof TokenResponse
     */
    tokenType?: string;
}


/**
 * Check if a given object implements the TokenResponse interface.
 */
export function instanceOfTokenResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "accessToken" in value;
    isInstance = isInstance && "idToken" in value;
    isInstance = isInstance && "clientId" in value;

    return isInstance;
}

export function TokenResponseFromJSON(json: any): TokenResponse {
    return TokenResponseFromJSONTyped(json, false);
}

export function TokenResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TokenResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessToken': json['accessToken'],
        'idToken': json['idToken'],
        'refreshToken': !exists(json, 'refreshToken') ? undefined : json['refreshToken'],
        'clientId': json['clientId'],
        'clientIdAliases': !exists(json, 'clientIdAliases') ? undefined : json['clientIdAliases'],
        'impersonatedClientId': !exists(json, 'impersonatedClientId') ? undefined : json['impersonatedClientId'],
        'expiresIn': !exists(json, 'expiresIn') ? undefined : json['expiresIn'],
        'tokenType': !exists(json, 'tokenType') ? undefined : json['tokenType'],
    };
}

export function TokenResponseToJSON(value?: TokenResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessToken': value.accessToken,
        'idToken': value.idToken,
        'refreshToken': value.refreshToken,
        'clientId': value.clientId,
        'clientIdAliases': value.clientIdAliases,
        'impersonatedClientId': value.impersonatedClientId,
        'expiresIn': value.expiresIn,
        'tokenType': value.tokenType,
    };
}

