/* tslint:disable */
/* eslint-disable */
/**
 * Tractable Identity Broker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PasswordPolicy
 */
export interface PasswordPolicy {
    /**
     * 
     * @type {number}
     * @memberof PasswordPolicy
     */
    minimumLength?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PasswordPolicy
     */
    requireLowercase?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PasswordPolicy
     */
    requireUppercase?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PasswordPolicy
     */
    requireNumbers?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PasswordPolicy
     */
    requireSymbols?: boolean;
}


/**
 * Check if a given object implements the PasswordPolicy interface.
 */
export function instanceOfPasswordPolicy(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PasswordPolicyFromJSON(json: any): PasswordPolicy {
    return PasswordPolicyFromJSONTyped(json, false);
}

export function PasswordPolicyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PasswordPolicy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'minimumLength': !exists(json, 'minimumLength') ? undefined : json['minimumLength'],
        'requireLowercase': !exists(json, 'requireLowercase') ? undefined : json['requireLowercase'],
        'requireUppercase': !exists(json, 'requireUppercase') ? undefined : json['requireUppercase'],
        'requireNumbers': !exists(json, 'requireNumbers') ? undefined : json['requireNumbers'],
        'requireSymbols': !exists(json, 'requireSymbols') ? undefined : json['requireSymbols'],
    };
}

export function PasswordPolicyToJSON(value?: PasswordPolicy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'minimumLength': value.minimumLength,
        'requireLowercase': value.requireLowercase,
        'requireUppercase': value.requireUppercase,
        'requireNumbers': value.requireNumbers,
        'requireSymbols': value.requireSymbols,
    };
}

