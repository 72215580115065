/* tslint:disable */
/* eslint-disable */
/**
 * Tractable Identity Broker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ConfirmAccountRequest,
  ConfirmForgotPasswordRequest,
  GenericError,
  IdentityProviderResponse,
  LoginExternalResponse,
  LoginRequest,
  LoginResponse,
  RefreshTokenRequest,
  RefreshTokenResponse,
  ResetPasswordRequest,
  TokenRequest,
  TokenResponse,
  UserPoolResponse,
  VerifyToken200Response,
  VerifyTokenRequest,
} from '../models';
import {
    ConfirmAccountRequestFromJSON,
    ConfirmAccountRequestToJSON,
    ConfirmForgotPasswordRequestFromJSON,
    ConfirmForgotPasswordRequestToJSON,
    GenericErrorFromJSON,
    GenericErrorToJSON,
    IdentityProviderResponseFromJSON,
    IdentityProviderResponseToJSON,
    LoginExternalResponseFromJSON,
    LoginExternalResponseToJSON,
    LoginRequestFromJSON,
    LoginRequestToJSON,
    LoginResponseFromJSON,
    LoginResponseToJSON,
    RefreshTokenRequestFromJSON,
    RefreshTokenRequestToJSON,
    RefreshTokenResponseFromJSON,
    RefreshTokenResponseToJSON,
    ResetPasswordRequestFromJSON,
    ResetPasswordRequestToJSON,
    TokenRequestFromJSON,
    TokenRequestToJSON,
    TokenResponseFromJSON,
    TokenResponseToJSON,
    UserPoolResponseFromJSON,
    UserPoolResponseToJSON,
    VerifyToken200ResponseFromJSON,
    VerifyToken200ResponseToJSON,
    VerifyTokenRequestFromJSON,
    VerifyTokenRequestToJSON,
} from '../models';

export interface CognitoCallbackRequest {
    code: string;
    state: string;
}

export interface ConfirmAccountOperationRequest {
    confirmAccountRequest?: ConfirmAccountRequest;
}

export interface ConfirmForgotPasswordOperationRequest {
    confirmForgotPasswordRequest?: ConfirmForgotPasswordRequest;
}

export interface GetUserPoolRequest {
    clientId: string;
    env: GetUserPoolEnvEnum;
}

export interface IdentityProvidersRequest {
    productId: string;
    clientId: string;
    env: IdentityProvidersEnvEnum;
}

export interface LoginOperationRequest {
    loginRequest?: LoginRequest;
}

export interface LoginExternalRequest {
    productId: string;
    clientId: string;
    redirectUri: string;
    identityProvider: string;
    codeChallenge: string;
    env: LoginExternalEnvEnum;
    impersonatedClientId?: string;
}

export interface LogoutRequest {
    authorization: string;
}

export interface RefreshTokenOperationRequest {
    refreshTokenRequest?: RefreshTokenRequest;
}

export interface ResetPasswordOperationRequest {
    resetPasswordRequest?: ResetPasswordRequest;
}

export interface TokenOperationRequest {
    tokenRequest?: TokenRequest;
}

export interface VerifyTokenOperationRequest {
    verifyTokenRequest?: VerifyTokenRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async cognitoCallbackRaw(requestParameters: CognitoCallbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling cognitoCallback.');
        }

        if (requestParameters.state === null || requestParameters.state === undefined) {
            throw new runtime.RequiredError('state','Required parameter requestParameters.state was null or undefined when calling cognitoCallback.');
        }

        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/cognito_callback`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async cognitoCallback(requestParameters: CognitoCallbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.cognitoCallbackRaw(requestParameters, initOverrides);
    }

    /**
     */
    async confirmAccountRaw(requestParameters: ConfirmAccountOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/confirm_account`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConfirmAccountRequestToJSON(requestParameters.confirmAccountRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async confirmAccount(requestParameters: ConfirmAccountOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.confirmAccountRaw(requestParameters, initOverrides);
    }

    /**
     */
    async confirmForgotPasswordRaw(requestParameters: ConfirmForgotPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/confirm_forgot_password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConfirmForgotPasswordRequestToJSON(requestParameters.confirmForgotPasswordRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async confirmForgotPassword(requestParameters: ConfirmForgotPasswordOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.confirmForgotPasswordRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getUserPoolRaw(requestParameters: GetUserPoolRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserPoolResponse>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling getUserPool.');
        }

        if (requestParameters.env === null || requestParameters.env === undefined) {
            throw new runtime.RequiredError('env','Required parameter requestParameters.env was null or undefined when calling getUserPool.');
        }

        const queryParameters: any = {};

        if (requestParameters.clientId !== undefined) {
            queryParameters['clientId'] = requestParameters.clientId;
        }

        if (requestParameters.env !== undefined) {
            queryParameters['env'] = requestParameters.env;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user_pool`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserPoolResponseFromJSON(jsonValue));
    }

    /**
     */
    async getUserPool(requestParameters: GetUserPoolRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserPoolResponse> {
        const response = await this.getUserPoolRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async identityProvidersRaw(requestParameters: IdentityProvidersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdentityProviderResponse>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling identityProviders.');
        }

        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling identityProviders.');
        }

        if (requestParameters.env === null || requestParameters.env === undefined) {
            throw new runtime.RequiredError('env','Required parameter requestParameters.env was null or undefined when calling identityProviders.');
        }

        const queryParameters: any = {};

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['clientId'] = requestParameters.clientId;
        }

        if (requestParameters.env !== undefined) {
            queryParameters['env'] = requestParameters.env;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/identity_providers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityProviderResponseFromJSON(jsonValue));
    }

    /**
     */
    async identityProviders(requestParameters: IdentityProvidersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdentityProviderResponse> {
        const response = await this.identityProvidersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async loginRaw(requestParameters: LoginOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginRequestToJSON(requestParameters.loginRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseFromJSON(jsonValue));
    }

    /**
     */
    async login(requestParameters: LoginOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResponse> {
        const response = await this.loginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async loginExternalRaw(requestParameters: LoginExternalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginExternalResponse>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling loginExternal.');
        }

        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling loginExternal.');
        }

        if (requestParameters.redirectUri === null || requestParameters.redirectUri === undefined) {
            throw new runtime.RequiredError('redirectUri','Required parameter requestParameters.redirectUri was null or undefined when calling loginExternal.');
        }

        if (requestParameters.identityProvider === null || requestParameters.identityProvider === undefined) {
            throw new runtime.RequiredError('identityProvider','Required parameter requestParameters.identityProvider was null or undefined when calling loginExternal.');
        }

        if (requestParameters.codeChallenge === null || requestParameters.codeChallenge === undefined) {
            throw new runtime.RequiredError('codeChallenge','Required parameter requestParameters.codeChallenge was null or undefined when calling loginExternal.');
        }

        if (requestParameters.env === null || requestParameters.env === undefined) {
            throw new runtime.RequiredError('env','Required parameter requestParameters.env was null or undefined when calling loginExternal.');
        }

        const queryParameters: any = {};

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['clientId'] = requestParameters.clientId;
        }

        if (requestParameters.impersonatedClientId !== undefined) {
            queryParameters['impersonatedClientId'] = requestParameters.impersonatedClientId;
        }

        if (requestParameters.redirectUri !== undefined) {
            queryParameters['redirectUri'] = requestParameters.redirectUri;
        }

        if (requestParameters.identityProvider !== undefined) {
            queryParameters['identityProvider'] = requestParameters.identityProvider;
        }

        if (requestParameters.codeChallenge !== undefined) {
            queryParameters['codeChallenge'] = requestParameters.codeChallenge;
        }

        if (requestParameters.env !== undefined) {
            queryParameters['env'] = requestParameters.env;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/login_external`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginExternalResponseFromJSON(jsonValue));
    }

    /**
     */
    async loginExternal(requestParameters: LoginExternalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginExternalResponse> {
        const response = await this.loginExternalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Invalidates all the user\'s tokens based on the token in the Authorization header
     */
    async logoutRaw(requestParameters: LogoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling logout.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Invalidates all the user\'s tokens based on the token in the Authorization header
     */
    async logout(requestParameters: LogoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.logoutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async refreshTokenRaw(requestParameters: RefreshTokenOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RefreshTokenResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/refresh_token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RefreshTokenRequestToJSON(requestParameters.refreshTokenRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RefreshTokenResponseFromJSON(jsonValue));
    }

    /**
     */
    async refreshToken(requestParameters: RefreshTokenOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RefreshTokenResponse> {
        const response = await this.refreshTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async resetPasswordRaw(requestParameters: ResetPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/reset_password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordRequestToJSON(requestParameters.resetPasswordRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async resetPassword(requestParameters: ResetPasswordOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.resetPasswordRaw(requestParameters, initOverrides);
    }

    /**
     */
    async tokenRaw(requestParameters: TokenOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TokenRequestToJSON(requestParameters.tokenRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenResponseFromJSON(jsonValue));
    }

    /**
     */
    async token(requestParameters: TokenOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenResponse> {
        const response = await this.tokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async verifyTokenRaw(requestParameters: VerifyTokenOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VerifyToken200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/verify`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VerifyTokenRequestToJSON(requestParameters.verifyTokenRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyToken200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async verifyToken(requestParameters: VerifyTokenOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VerifyToken200Response> {
        const response = await this.verifyTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetUserPoolEnvEnum = {
    Dev: 'dev',
    StagingEu: 'staging-eu',
    PreprodEu: 'preprod-eu',
    ProdEu: 'prod-eu',
    PreprodUs: 'preprod-us',
    ProdUs: 'prod-us',
    PreprodJp: 'preprod-jp',
    ProdJp: 'prod-jp'
} as const;
export type GetUserPoolEnvEnum = typeof GetUserPoolEnvEnum[keyof typeof GetUserPoolEnvEnum];
/**
 * @export
 */
export const IdentityProvidersEnvEnum = {
    Dev: 'dev',
    StagingEu: 'staging-eu',
    PreprodEu: 'preprod-eu',
    ProdEu: 'prod-eu',
    PreprodUs: 'preprod-us',
    ProdUs: 'prod-us',
    PreprodJp: 'preprod-jp',
    ProdJp: 'prod-jp'
} as const;
export type IdentityProvidersEnvEnum = typeof IdentityProvidersEnvEnum[keyof typeof IdentityProvidersEnvEnum];
/**
 * @export
 */
export const LoginExternalEnvEnum = {
    Dev: 'dev',
    StagingEu: 'staging-eu',
    PreprodEu: 'preprod-eu',
    ProdEu: 'prod-eu',
    PreprodUs: 'preprod-us',
    ProdUs: 'prod-us',
    PreprodJp: 'preprod-jp',
    ProdJp: 'prod-jp'
} as const;
export type LoginExternalEnvEnum = typeof LoginExternalEnvEnum[keyof typeof LoginExternalEnvEnum];
